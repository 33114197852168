import { isValidNumber, formatNumber } from "libphonenumber-js"
import {
  EMAIL,
  EMAIL_ERROR,
  LINK,
  LINK_ERROR,
  PHONE,
  PHONE_ERROR,
  REQUIRED,
} from "../constants/messages"
import { isEmail, isURL } from "validator"
const emptyProxyObject = new Proxy({}, {})
const isEmpty = val => {
  if (val === emptyProxyObject) return true
  if (val === undefined) return true

  if (
    typeof val == "function" ||
    typeof val == "number" ||
    typeof val == "boolean" ||
    Object.prototype.toString.call(val) === "[object Date]"
  )
    return false

  if (val == null || val.length === 0)
    // null or 0 length array
    return true

  if (typeof val == "object") if (Object.keys(val).length === 0) return true

  return false
}

export default isEmpty
export const validatePhone = input => {
  const isValid = isValidNumber(input)
  return isValid
}

export const onFormFeildsChange = (event, formData, setFormData) => {
  const { value, name } = event.target
  console.log(value, name)
  const newFormData = { ...formData }
  newFormData[name]["value"] = value
  validateField(name, newFormData, setFormData)
  setFormData(newFormData)
}

export const enableShouldErrorShow = (name, formData, setFormData) => {
  const newFormData = { ...formData }
  newFormData[name]["shouldShowError"] = true
  setFormData(newFormData)
}

export const disableShouldErrorShow = (name, formData, setFormData) => {
  const newFormData = { ...formData }
  newFormData[name]["shouldShowError"] = false
  setFormData(newFormData)
}

export const validateEmail = input => {
  return isEmail(input)
}
export const validateURL = link => {
  return isURL(link)
}

export const validateField = (fieldName, formDataOrignal, setFormData) => {
  const formData = {
    ...formDataOrignal,
  }
  const checkValidationArray = formData[fieldName]["check"]
  const fieldValue = formData[fieldName]["value"]
  let isValid = true

  if (checkValidationArray?.includes(REQUIRED) && isEmpty(fieldValue)) {
    formData[fieldName]["error"] = REQUIRED
    isValid = false
  } else {
    formData[fieldName]["error"] = ""
  }

  if (checkValidationArray.includes(PHONE)) {
    if (!isEmpty(fieldValue)) {
      if (validatePhone(fieldValue)) {
        isValid = true
      } else {
        formData[fieldName]["error"] = PHONE_ERROR
        isValid = false
      }
    }
  }
  if (checkValidationArray.includes(EMAIL)) {
    if (!isEmpty(fieldValue)) {
      if (validateEmail(fieldValue)) {
        isValid = true
      } else {
        formData[fieldName]["error"] = EMAIL_ERROR
        isValid = false
      }
    }
  }
  if (checkValidationArray.includes(LINK)) {
    if (!isEmpty(fieldValue)) {
      if (validateURL(fieldValue)) {
        isValid = true
      } else {
        formData[fieldName]["error"] = LINK_ERROR
        isValid = false
      }
    }
  }

  setFormData(formData)
  return isValid
}
