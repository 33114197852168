import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"
import { MessageSquare } from "react-feather"
import link from "../assets/images/link.png"
import ChatIcon from "../assets/images/comments.png"
import MeetIcon from "../assets/images/meet.png"
import PhoneIcon from "../assets/images/telephone.png"
import MailIcon from "../assets/images/gmail.png"

import emailjs from "@emailjs/browser"
import {
  disableShouldErrorShow,
  enableShouldErrorShow,
  onFormFeildsChange,
  validateField,
} from "../utils/formValidator"
import { EMAIL, REQUIRED, PHONE, LINK } from "../constants/messages"
import {
  EMAIL_SERVICE_ID,
  EMAIL_TEMPLATE_ID,
  EMAIL_USER_ID,
} from "../constants/EmailConstants"
import ScheduleMeetingDialog from "./ScheduleMeetingDialog.js"

const GetInTouch = () => {
  const [formdata, setFormdata] = useState({
    name: {
      value: "",
      error: "",
      check: [REQUIRED],
      shouldShowError: false,
    },
    email: {
      value: "",
      error: "",
      check: [EMAIL, REQUIRED],
      shouldShowError: false,
    },
    mobile: {
      value: "",
      error: "",
      check: [PHONE],
      shouldShowError: false,
    },
    companyWebsite: {
      value: "",
      error: "",
      check: [LINK],
      shouldShowError: false,
    },
    message: {
      value: "",
      error: "",
      check: [REQUIRED],
      shouldShowError: false,
    },
  })
  const [sucess, setSucess] = useState(false)
  const [nameError, setNameError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [messageError, setMessageError] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)
  const [showCalendlyWidget, setShowCalendlyWidget] = useState(false)

  const submitHandler = (event: any) => {
    event.preventDefault()
    let isValid = true
    Object.keys(formdata)?.forEach(key => {
      enableShouldErrorShow(key, formdata, setFormdata)
      if (!validateField(key, formdata, setFormdata)) {
        isValid = false
      }
    })

    const templateParams = {
      sender_name: formdata?.name?.value,
      sender_email: formdata?.email?.value,
      sender_message: formdata?.message?.value,
      sender_phone: formdata?.mobile?.value,
      sender_company: formdata?.companyWebsite?.value,
    }
    if (isValid) {
      setShowSpinner(true)
      emailjs
        .send(
          EMAIL_SERVICE_ID,
          EMAIL_TEMPLATE_ID,
          templateParams,
          EMAIL_USER_ID
        )
        .then(
          response => {
            if (response.status === 200) {
              Object.values(formdata)?.forEach(field => {
                field.value = ""
                field.error = ""
                field.shouldShowError = false
              })
              setShowSpinner(false)
              setSucess(true)
              setTimeout(() => {
                setSucess(false)
              }, 7000)
            }
          },
          error => {
            setShowSpinner(false)
          }
        )
    }
  }

  const handleScheduleMeeting = event => {
    event?.preventDefault()
    setShowCalendlyWidget(true)
  }
  function openZohoChatPopup() {
    if (typeof $zoho !== "undefined") {
      if ($zoho.salesiq) {
        $zoho.salesiq.floatwindow.visible("show")
      } else {
        window.open("https://join.skype.com/invite/rXk997CNZgM8", "_blank")
      }
    } else {
      window.open("https://join.skype.com/invite/rXk997CNZgM8", "_blank")
    }
  }

  return (
    <section className="get-in-touch " id="get-in-touch">
      <div className="main-banner r-margin ptb-80 p-t-sm-0 p-b-sm-40">
        <div className="container">
          <div className="section-title">
            <h2 className="hover-link m-t-sm-16">
              <span>
                <a href="#get-in-touch">
                  <img src={link} alt="" />
                </a>
                Get in touch
              </span>
            </h2>
            <div className="bar"></div>
            <p>
              Let's get in touch to discuss how you could achieve your business
              goals building tailor made app for your business
            </p>
          </div>
        </div>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-lg-8">
                  <div className="banner-form ml-3">
                    <div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>
                              Name <span className="mandatory">*</span>
                            </label>
                            <input
                              name="name"
                              type="text"
                              onBlur={() => {
                                if (typeof $zoho !== "undefined") {
                                  if ($zoho.salesiq) {
                                    $zoho.salesiq.visitor.name(
                                      `${formdata?.name?.value}`
                                    )
                                  }
                                }
                              }}
                              value={formdata?.name?.value}
                              className="form-control"
                              placeholder="Enter Name"
                              onChange={event => {
                                onFormFeildsChange(event, formdata, setFormdata)
                                disableShouldErrorShow(
                                  "name",
                                  formdata,
                                  setFormdata
                                )
                              }}
                            />
                            {formdata?.name?.error && (
                              <span className="text-danger">
                                {formdata?.name?.error}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>
                              Email <span className="mandatory">*</span>
                            </label>
                            <input
                              onBlur={() => {
                                if (typeof $zoho !== "undefined") {
                                  if ($zoho.salesiq) {
                                    $zoho.salesiq.visitor.email(
                                      `${formdata?.email?.value}`
                                    )
                                  }
                                }
                              }}
                              name="email"
                              value={formdata?.email?.value}
                              type="text"
                              className="form-control"
                              placeholder="Enter your email"
                              onChange={event => {
                                onFormFeildsChange(event, formdata, setFormdata)
                                disableShouldErrorShow(
                                  "email",
                                  formdata,
                                  setFormdata
                                )
                              }}
                            />
                            {formdata?.email?.error && (
                              <span className="text-danger">
                                {formdata?.email?.error}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Mobile Number</label>
                            <input
                              onBlur={() => {
                                if (typeof $zoho !== "undefined") {
                                  if ($zoho.salesiq) {
                                    $zoho.salesiq.visitor.contactnumber(
                                      `${formdata?.mobile?.value}`
                                    )
                                  }
                                }
                              }}
                              name="mobile"
                              type="text"
                              value={formdata?.mobile?.value}
                              className="form-control"
                              placeholder="Enter Mobile Number"
                              onChange={event => {
                                onFormFeildsChange(event, formdata, setFormdata)
                                disableShouldErrorShow(
                                  "mobile",
                                  formdata,
                                  setFormdata
                                )
                              }}
                            />
                            {formdata?.mobile?.error && (
                              <span className="text-danger">
                                {formdata?.mobile?.error}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Company Website</label>
                            <input
                              onBlur={() => {
                                if (typeof $zoho !== "undefined") {
                                  if ($zoho.salesiq) {
                                    $zoho.salesiq.visitor.info(
                                      {
                                        Website: `${formdata?.companyWebsite?.value}`,
                                      },
                                      {
                                        Message: `${formdata?.message?.value}`,
                                      }
                                    )
                                  }
                                }
                              }}
                              name="companyWebsite"
                              value={formdata?.companyWebsite?.value}
                              type="text"
                              className="form-control"
                              placeholder="Enter Company Website"
                              onChange={event => {
                                onFormFeildsChange(event, formdata, setFormdata)
                                disableShouldErrorShow(
                                  "companyWebsite",
                                  formdata,
                                  setFormdata
                                )
                              }}
                            />
                            {formdata?.companyWebsite?.error && (
                              <span className="text-danger">
                                {formdata?.companyWebsite?.error}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>
                              Message <span className="mandatory">*</span>
                            </label>
                            <textarea
                              onBlur={() => {
                                if (typeof $zoho !== "undefined") {
                                  if ($zoho.salesiq) {
                                    $zoho.salesiq.visitor.info(
                                      {
                                        Website: `${formdata?.companyWebsite?.value}`,
                                      },
                                      {
                                        Message: `${formdata?.message?.value}`,
                                      }
                                    )
                                  }
                                }
                              }}
                              name="message"
                              value={formdata?.message?.value}
                              cols={30}
                              rows={5}
                              placeholder="Write your message..."
                              className="form-control"
                              onChange={event => {
                                onFormFeildsChange(event, formdata, setFormdata)
                                disableShouldErrorShow(
                                  "message",
                                  formdata,
                                  setFormdata
                                )
                              }}
                            ></textarea>
                            {formdata?.message?.error && (
                              <span className="text-danger">
                                {formdata?.message?.error}
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          className={`col-lg-12 d-flex justify-content-between`}
                        >
                          <span></span>
                          <button
                            onClick={submitHandler}
                            className="btn btn-primary"
                          >
                            {showSpinner && (
                              <div
                                style={{
                                  width: 20,
                                  height: 20,
                                  left: 10,
                                }}
                                className="spinner-border position-absolute"
                                role="status"
                              >
                                <span className="visually-hidden ">
                                  Loading...
                                </span>
                              </div>
                            )}
                            Submit{" "}
                          </button>
                        </div>
                        {sucess && (
                          <div className="mt-3">
                            <p className=" text-success">
                              Your message has been sent successfully!
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="other-contact-info">
                      <p>Alternatively you could also get in touch here</p>
                      <div className="content d-flex flex-wrap ">
                        <span className="col-12 col-sm-8 col-md-8 col-lg-7 mt-3 mt-md-0 d-flex align-items-center">
                          <img src={MailIcon} alt="text" height={28} />
                          <a
                            href="mailto:sumit.chakole@tarkashilpa.com"
                            style={{ paddingLeft: 15, fontSize: 15 }}
                          >
                            sumit.chakole@tarkashilpa.com
                          </a>
                        </span>

                        <span className="col-12 col-sm-4 col-md-4 col-lg-5 mt-3 mt-md-0 d-flex align-items-center">
                          <img
                            src={ChatIcon}
                            alt="text"
                            height={36}
                            style={{ content: "inherit" }}
                          />
                          <a
                            type="button "
                            onClick={openZohoChatPopup}
                            style={{
                              paddingLeft: 10,
                              fontSize: 15,
                              cursor: "pointer",
                            }}
                          >
                            sumit.chakole
                          </a>
                        </span>
                        <span className="col-12 col-sm-8 col-md-8  col-lg-7 mt-3 d-flex align-items-center">
                          <img src={PhoneIcon} alt="text" height={32} />
                          <a
                            href="tel:7738811194"
                            style={{ paddingLeft: 10, fontSize: 15 }}
                          >
                            +91-7738811194
                          </a>
                        </span>
                        <span
                          className="col-12 col-sm-4 col-md-4 col-lg-5 mt-3 d-flex align-items-center"
                          onClick={handleScheduleMeeting}
                        >
                          <img src={MeetIcon} alt="text" height={32} />
                          <a href="" style={{ paddingLeft: 10, fontSize: 15 }}>
                            Schedule Meeting
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCalendlyWidget && (
        <ScheduleMeetingDialog
          show={showCalendlyWidget}
          hide={setShowCalendlyWidget}
        />
      )}
    </section>
  )
}

export default GetInTouch
