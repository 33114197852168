import React, { useEffect } from "react"
import { Helmet } from "react-helmet"

const ScheduleMeetingDialog = ({ show, hide }) => {
  return (
    <div className="modal">
      <Helmet>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        ></script>
      </Helmet>
      <div className="modal-content">
        <button
          className="modal-close"
          onClick={() => {
            hide(false)
          }}
        >
          X
        </button>

        <div
          className="calendly-inline-widget"
          data-url="https://calendly.com/tarkashilpa/30min"
          style={{
            height: `100%`,
          }}
        />
      </div>
      <div
        className="modal-overlay"
        onClick={() => {
          hide(false)
        }}
      ></div>
    </div>
  )
}

export default ScheduleMeetingDialog
